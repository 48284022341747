.carousel-card {
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    margin: 1px;
    background: #ffffff;
    border: 0 !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
  
  // .carousel-card:hover {
  //   box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  // }
  
  .star__icon__size {
    font-size: 14px;
  }
  
  .stylist__title-text {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  
  .stylist__reviews-text {
    font-size: 14px;
    text-align: center;
    color: #666;
  }
  
  .stylist__specialization-text {
    color: #1ea6f2;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
  
  .stylist__about-text {
    color: #7a7a7a;
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
  }
  
  .stylist__location-icon {
    margin-right: 5px;
    color: #e70022;
  }
  
  .stylist__address-details {
    font-size: 14px;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #555;
  }
  
  .stylist-image {
    height: 147px !important;
    width: auto;
    border-radius: 5px 5px 0 0;
  }
  
  .stylist__get-appointment-btn {
    background-color: #393434 !important;
    font-size: 12px !important;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
  }
  
  .stylist__offers {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
  }
  
  .ticker-container {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;
  }
  
  .ticker-content {
    display: inline-block;
    white-space: nowrap;
    animation: ticker-scroll 15s linear infinite;
  }
  
  @keyframes ticker-scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  