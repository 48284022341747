/* Scissors cutting animation - continuously cuts */
.scissors-animation {
  animation: scissorCut 0.5s infinite ease-in-out;
  transform-origin: center;
}

/* When scissors move backward, they rotate 180 degrees */
.scissors-backward {
  transform: scaleX(-1) !important;
}

@keyframes scissorCut {
  0% { transform: scaleY(0.6) scaleX(1); }
  50% { transform: scaleY(1) scaleX(0.7); }
  100% { transform: scaleY(0.6) scaleX(1); }
}

/* Animation for backward direction */
.scissors-backward.scissors-animation {
  animation: scissorCutBackward 0.5s infinite ease-in-out;
}

@keyframes scissorCutBackward {
  0% { transform: scaleX(-1) scaleY(0.6) scaleX(1); }
  50% { transform: scaleX(-1) scaleY(1) scaleX(0.7); }
  100% { transform: scaleX(-1) scaleY(0.6) scaleX(1); }
}
